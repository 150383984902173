import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const legalEntitiesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Состояние юридических лиц',
    active: true
  }
];

export type LegalEntitiesType = {
  name: string;
  status: string;
  is_default: string;
  guid: string;
};
