/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import categoryService from 'services/category';
import countryService from 'services/country';
import unitService from 'services/units';
import productKindService from 'services/productKind';
import nomenclatureService from 'services/nomenclature';
import genBarcodeService from 'services/generate';
import brandService from 'services/brand';
import SpecificationInfo from './specification';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import VariationInfo from './variation';
import UpdataNomVariationTable from './variation/UpdataNomVariationTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

interface CreateNomenclatureProps {
  setOpenNomenclature: (show: boolean) => void;
  selectedNomenclatureId?: any;
  refetch?: any;
}

export type CharacterType = {
  character_name: string;
  values: string;
  specification_id: string;
  id: string;
};

export type VariationType = {
  product: string;
  var_barcode: string;
  vendor_code: string;
  party_number: string;
  code: string;
  var_data: any;
};

interface ProductVariation {
  name: string;
  values: { value: string }[];
}

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => field.onChange(e.target.checked)}
    />
  );
};

const CreateNomenclature = ({
  setOpenNomenclature,
  selectedNomenclatureId,
  refetch
}: CreateNomenclatureProps) => {
  const { register, handleSubmit, control, reset, setValue, watch } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [measureUnitOption, setMeasureUnitOption] = useState([]);
  const [productKindOption, setProductKindOption] = useState([]);
  const [brandOption, setBrandOption] = useState([]);
  const [openCharacter, setOpenCharacter] = useState(false);
  const [openVariation, setOpenVariation] = useState(false);
  const [openUpdateVariation, setOpenUpdateVariataion] = useState(false);
  const [selectedUpdateVariationId, setSelectedUpdateVariationId] =
    useState('');
  const [characterFormData, setCharacterFormData] = useState<CharacterType[]>(
    []
  );
  const [variationFormData, setVariationFormData] = useState<VariationType[]>(
    []
  );

  const handleRemoveItem = (indexToRemove: number) => {
    setVariationFormData(
      variationFormData.filter((_, index) => index !== indexToRemove)
    );
  };

  //GET Activity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await categoryService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.categories?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setCategoryOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.ru
      }));
      setCountryOption(options);
    });
  });

  //GET Measure Unit to options
  useQuery(['GET_MEASURE_UNIT'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option.id,
        label: option.name?.ru
      }));
      setMeasureUnitOption(options);
    });
  });

  //GET Product kind to options
  useQuery(['GET_PRODUCT_KIND'], async () => {
    await productKindService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.product_kinds?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setProductKindOption(options);
      });
  });

  //GET Brand to options
  useQuery(['GET_BRAND'], async () => {
    await brandService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.custom_brands?.map((option: any) => ({
        value: option.id,
        label: option.name?.ru
      }));
      setBrandOption(options);
    });
  });

  //Variation

  const variationTableColumns: ColumnDef<VariationType>[] = [
    {
      accessorKey: 'product',
      header: 'Товар',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'var_barcode',
      header: 'Штрих-коде',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'vendor_code',
      header: 'Артикул',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'party_number',
      header: 'Партийный номер',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'code',
      header: 'КОД ИКПУ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenUpdateVariataion(true);
                setSelectedUpdateVariationId(rowData?.row?.id);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleRemoveItem(+rowData?.row?.id)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const tableVariation = useAdvanceTable({
    data: variationFormData,
    columns: variationTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const isVariation = watch('is_variation');

  //Get by id
  const fetchData = useMemo(() => {
    return () => {
      if (selectedNomenclatureId === '') return setLoading(false);

      nomenclatureService
        .getNomenclatureById(selectedNomenclatureId)
        .then((res: any) => {
          const computed = {
            ...res,
            vendor_code: res?.article,
            country_id: {
              value: res?.country_id,
              label: res?.country?.name?.ru
            },
            category_id: {
              value: res?.category_id,
              label: res?.category?.name?.ru
            },

            measure_unit_id: {
              value: res?.measure_unit_id,
              label: res?.measure_unit?.name?.ru
            },

            product_kind_id: {
              value: res?.product_kind_id,
              label: res?.productKind?.name?.ru
            },

            custom_brand_id: {
              value: res?.custom_brand_id,
              label: res?.custom_brand?.name?.ru
            },
            variation_id: res?.variation_id
          };
          reset(computed);

          const newSpecificationData = res?.specifications?.map(
            (spec: any) => ({
              specification_id: spec?.specification?.id ?? '',
              values: spec?.value ?? '',
              character_name: spec?.specification.name?.ru ?? '',
              id: spec?.id ?? ''
            })
          );

          setCharacterFormData(newSpecificationData ?? []);

          const newVariationData = res?.variations?.map(
            (variation: any, index: any) => {
              const productVariations: ProductVariation[] =
                variation?.variations.reduce((acc: any, curr: any) => {
                  const existingVariation = acc.find(
                    (v: any) => v.name === curr.variation?.name?.ru
                  );
                  const newValue = { value: curr.value };
                  if (existingVariation) {
                    existingVariation.values.push(newValue);
                  } else {
                    acc.push({
                      name: curr.variation?.name?.ru,
                      values: [newValue]
                    });
                  }
                  return acc;
                }, []);

              const cartesian = (...a: any) =>
                a.reduce((a: any, b: any) =>
                  a.flatMap((d: any) => {
                    const arrD = Array.isArray(d) ? d : [d];
                    return b.flatMap((e: any) => {
                      const arrE = Array.isArray(e) ? e : [e];
                      return arrD
                        .map((itemD: any) =>
                          arrE.map((itemE: any) => [].concat(itemD, itemE))
                        )
                        .reduce((acc: any, val: any) => acc.concat(val), []);
                    });
                  })
                );
              const productValues = productVariations.map(
                ({ values }) => values
              );

              const permutations = cartesian(...productValues).map(
                (e: any) => ({
                  product:
                    e.length > 0
                      ? e?.map(({ value }: { value: any }) => value).join(' | ')
                      : e?.value
                })
              );

              return {
                nomenclature_id: variation.nomenclature_id,
                var_barcode: variation.barcode,
                product:
                  permutations?.length === 1
                    ? permutations[0]?.product
                    : permutations[index].product,
                vendor_code: variation?.article,
                party_number: variation?.part_number,
                code: variation?.ikpu,
                var_data: variation?.variations?.map((item: any) => ({
                  var_value: item.value,
                  variation: item.variation.name.ru,
                  variation_id: item.variation_id,
                  nomenclature_id: item?.nomenclature_id,
                  id: item?.id
                }))
              };
            }
          );

          res?.is_variation === true && setVariationFormData(newVariationData);
        })
        .finally(() => setLoading(false));
    };
  }, [selectedNomenclatureId]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedNomenclatureId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const specificationData = characterFormData?.map(el => {
      return {
        specification_id: el?.specification_id,
        value: el?.values
      };
    });

    const variationArray = variationFormData?.map((item: any) => ({
      barcode: item?.var_barcode,
      article: item?.vendor_code,
      part_number: item?.party_number,
      ikpu: item?.code,
      variations: item?.var_data?.map((variation: any) => ({
        value: variation?.var_value,
        variation_id: variation?.variation_id
      }))
    }));

    const createdDataWithOutVariation = {
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id?.value,
      barcode: +data?.barcode,
      vendor_code: data?.vendor_code,
      part_number: data?.part_number,
      name: data?.name,
      short_name: data?.short_name,
      custom_brand_id: data?.custom_brand_id?.value,
      measure_unit_id: data?.measure_unit_id.value,
      country_id: data?.country_id.value,
      ikpu: data?.ikpu,
      category_id: data?.category_id.value,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      specification:
        characterFormData.length > 0 ? specificationData : undefined
    };

    const createdDataWithVariation = {
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id?.value,
      name: data?.name,
      short_name: data?.short_name,
      custom_brand_id: data?.custom_brand_id?.value,
      measure_unit_id: data?.measure_unit_id.value,
      country_id: data?.country_id.value,
      category_id: data?.category_id.value,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      variation: variationArray,
      specification:
        characterFormData.length > 0 ? specificationData : undefined
    };

    nomenclatureService
      .createNomenclature(
        !isVariation ? createdDataWithOutVariation : createdDataWithVariation
      )
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Номенклатура успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setOpenNomenclature(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const specificationDataUpdata = characterFormData?.map(el => {
      return {
        specification_id: el?.specification_id ?? '',
        value: el?.values ?? '',
        id: el?.id ? el?.id : undefined
      };
    });

    const variationArray = variationFormData?.map((item: any) => ({
      barcode: item?.var_barcode,
      article: item?.vendor_code,
      part_number: item?.party_number,
      ikpu: item?.code,
      nomenclature_id: item?.nomenclature_id
        ? item?.nomenclature_id
        : undefined,
      variations: item?.var_data?.map((variation: any) => ({
        value: variation?.var_value,
        variation_id: variation?.variation_id,
        id: variation?.id,
        nomenclature_id: variation?.nomenclature_id
          ? variation?.nomenclature_id
          : undefined
      }))
    }));

    const updateDataWithOutVariation = {
      id: selectedNomenclatureId,
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id?.value,
      barcode: +data?.barcode,
      vendor_code: data?.vendor_code,
      part_number: data?.part_number,
      name: data?.name,
      short_name: data?.short_name,
      custom_brand_id: data?.custom_brand_id?.value,
      measure_unit_id: data?.measure_unit_id.value,
      country_id: data?.country_id.value,
      ikpu: data?.ikpu,
      category_id: data?.category_id.value,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      specification:
        characterFormData.length > 0 ? specificationDataUpdata : undefined
    };

    const updateDataWithVariation = {
      id: selectedNomenclatureId,
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id?.value,
      name: data?.name,
      short_name: data?.short_name,
      custom_brand_id: data?.custom_brand_id?.value,
      measure_unit_id: data?.measure_unit_id.value,
      country_id: data?.country_id.value,
      category_id: data?.category_id.value,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      variation: variationArray,
      variation_id: data?.variation_id,
      specification:
        characterFormData.length > 0 ? specificationDataUpdata : undefined
    };

    nomenclatureService
      .updateNomenclature(
        selectedNomenclatureId,
        !isVariation ? updateDataWithOutVariation : updateDataWithVariation
      )
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenNomenclature(false);
      });
  };

  //Generate barcode
  const handleGenerate = () => {
    setLoading(true);
    genBarcodeService
      .getGenBarcode({ offset: 0, limit: 100 })
      .then((res: any) => {
        setValue('barcode', res?.barcode);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedNomenclatureId !== '') {
      fetchData();
    }
  }, [selectedNomenclatureId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-2">
          <Col>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_variation"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label="Вариативный товар" />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>Тип товара</Form.Label>
            <div className="react-select-container">
              <Controller
                name="product_kind_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select product kind"
                    options={productKindOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        {!isVariation && (
          <Row className="mb-2">
            <Col style={{ display: 'flex', gap: '20px' }}>
              <Form.Group className="md-4 flex-1">
                <Form.Label>Штрих-код</Form.Label>
                <Form.Control
                  {...register(`barcode`)}
                  type="number"
                  placeholder={`Штрих-код`}
                />
              </Form.Group>
              <Form.Group className="mt-auto">
                <Button variant="primary" onClick={() => handleGenerate()}>
                  Сгенерировать
                </Button>
              </Form.Group>
            </Col>
          </Row>
        )}

        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Group className="md-4">
                <Form.Label>Артикул</Form.Label>
                <Form.Control
                  {...register(`vendor_code`)}
                  type="text"
                  placeholder={`Артикул`}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Group className="md-4">
                <Form.Label>Партийный номер</Form.Label>
                <Form.Control
                  {...register(`part_number`)}
                  type="text"
                  placeholder={`Партийный номер`}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Полный наименование товара</Form.Label>
              <Form.Control
                {...register(`name`)}
                type="text"
                placeholder={`Полный наименование товара`}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Короткий наименование товара</Form.Label>
              <Form.Control
                {...register(`short_name`)}
                type="text"
                placeholder={`Короткий наименование товара`}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>Бренды</Form.Label>
            <div className="react-select-container">
              <Controller
                name="custom_brand_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select brand"
                    options={brandOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>Единица измерения</Form.Label>
            <div className="react-select-container">
              <Controller
                name="measure_unit_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select measure unit"
                    options={measureUnitOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>Страна</Form.Label>
            <div className="react-select-container">
              <Controller
                name="country_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select country"
                    options={countryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Group className="md-4">
                <Form.Label>Код ИКПУ</Form.Label>
                <Form.Control
                  {...register(`ikpu`)}
                  type="text"
                  placeholder={`Код ИКПУ`}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Form.Label>Категория</Form.Label>
            <div className="react-select-container">
              <Controller
                name="category_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select category"
                    options={categoryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        {isVariation && (
          <Row className="mb-2">
            <Col className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => setOpenVariation(true)}
              >
                Добавить Вариацию
              </Button>
            </Col>
          </Row>
        )}

        {isVariation && (
          <AdvanceTableProvider {...tableVariation}>
            <div className="px-4 px-lg-6 mb-4 border-top border-bottom border-300 position-relative">
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </AdvanceTableProvider>
        )}

        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              onClick={() => setOpenCharacter(true)}
            >
              Добавить Характеристика
            </Button>
          </Col>
        </Row>

        <SpecificationInfo
          setOpenCharacter={setOpenCharacter}
          openCharacter={openCharacter}
          characterFormData={characterFormData}
          setCharacterFormData={setCharacterFormData}
        />

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Синонимы</Form.Label>
              <Form.Control
                {...register(`synonyms`)}
                type="text"
                placeholder={`Синонимы `}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Описания</Form.Label>
              <Form.Control
                {...register('description')}
                as="textarea"
                rows={3}
                placeholder="Описания"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_18plus"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label="18+ товар" />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          Добавить
        </Button>

        <Modal
          show={openVariation}
          onHide={() => setOpenVariation(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="border-top border-500">
            <Modal.Title id="contained-modal-title-vcenter">
              Вариация
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-bottom border-500">
            <VariationInfo
              setOpenVariation={setOpenVariation}
              variationFormData={variationFormData}
              setVariationFormData={setVariationFormData}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={openUpdateVariation}
          onHide={() => {
            setOpenUpdateVariataion(false);
            setSelectedUpdateVariationId('');
          }}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-char"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Вариация
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdataNomVariationTable
              setOpenUpdateVariataion={setOpenUpdateVariataion}
              variationFormData={variationFormData}
              selectedUpdateVariationId={selectedUpdateVariationId}
              setVariationFormData={setVariationFormData}
            />
          </Modal.Body>
        </Modal>
      </Form>
    </div>
  );
};

export default CreateNomenclature;
