import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilChartPie } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'directories',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Справочники',
        icon: 'book-open',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Языки',
            path: '/',
            pathName: 'default-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },

          {
            name: 'Страна',
            path: '/directories/country',
            pathName: 'country-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Регион',
            path: '/directories/region',
            pathName: 'region-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Район',
            path: '/directories/area',
            pathName: 'area-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Валюта',
            path: '/directories/currency',
            pathName: 'currency-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Типы единиц измерения',
            path: '/directories/measurement',
            pathName: 'measurement-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Единица измерения',
            path: '/directories/units',
            pathName: 'units-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Вариация',
            path: '/directories/variation',
            pathName: 'variation-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Характеристика',
            path: '/directories/characteristic',
            pathName: 'characteristic-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Вид деятельности',
            path: '/directories/activity',
            pathName: 'activity-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Категория',
            path: '/directories/category',
            pathName: 'category-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Тип товара',
            path: '/directories/product',
            pathName: 'product-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Состояние товара',
            path: '/directories/state',
            pathName: 'state-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Тип расчета',
            path: '/directories/calculation',
            pathName: 'calculation-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Тип оплат',
            path: '/directories/payment',
            pathName: 'payment-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Назначения оплат',
            path: '/directories/purpose',
            pathName: 'purpose-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Порядок оплат',
            path: '/directories/procedure',
            pathName: 'procedure-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'НДС',
            path: '/directories/nds',
            pathName: 'nds-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Типы пользователей',
            path: '/directories/userstype',
            pathName: 'userstype-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Состояние юридических лиц',
            path: '/directories/entities',
            pathName: 'entities-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Доступы юридических лиц',
            path: '/directories/accesses',
            pathName: 'accesses-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          // {
          //   name: 'Пол',
          //   path: '/directories/gender',
          //   pathName: 'gender-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'Метод оценки',
            path: '/directories/method',
            pathName: 'method-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Временная зона',
            path: '/directories/timezone',
            pathName: 'timezone-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Виды пополнения баланса',
            path: '/directories/replenishment',
            pathName: 'replenishment-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Тип закупа',
            path: '/directories/purchase',
            pathName: 'purchase-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Причина возврат товара',
            path: '/directories/returning',
            pathName: 'returning-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Состояние документа',
            path: '/directories/documents',
            pathName: 'documents-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Состояние инвентаризации',
            path: '/directories/inventory',
            pathName: 'inventory-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Финансы',
            active: true,
            pages: [
              {
                name: 'Счета',
                path: '/directories/finance/accounts',
                pathName: 'accounts-finance-directories',
                topNavIcon: 'clipboard',
                active: true
              },
              {
                name: 'Статьи',
                path: '/directories/finance/articles',
                pathName: 'articles-finance-directories',
                active: true
              }
            ]
          },
          {
            name: 'Бонусы',
            active: true,
            pages: [
              {
                name: 'Область применения',
                path: '/directories/bonuses/application',
                pathName: 'application-bonuses-directories',
                topNavIcon: 'clipboard',
                active: true
              },
              {
                name: 'Активация бонуса',
                path: '/directories/bonuses/activation',
                pathName: 'activation-bonuses-directories',
                topNavIcon: 'clipboard',
                active: true
              }
              // {
              //   name: 'Способ оплаты',
              //   path: '/directories/bonuses/payment',
              //   pathName: 'payment-bonuses-directories',
              //   topNavIcon: 'clipboard',
              //   active: true
              // }
            ]
          },
          {
            name: 'Источник расхода',
            path: '/directories/expense-source',
            pathName: 'expense-source-directories',
            topNavIcon: 'clipboard',
            active: true
          },
          {
            name: 'Бонусная активация',
            path: '/directories/bonus-activation',
            pathName: 'bonus-activation-directories',
            active: true
          },
          {
            name: 'Бонусная заявка',
            path: '/directories/bonus-application',
            pathName: 'bonus-application-directories',
            active: true
          },
          {
            name: 'Бонусная выплата',
            path: '/directories/bonus-payment',
            pathName: 'bonus-payment-directories',
            active: true
          },
          {
            name: 'Степень оценки',
            path: '/directories/rate-degree',
            pathName: 'rate-degree-directories',
            active: true
          },
          // {
          //   name: 'Субъекты права',
          //   path: '/directories/subjects',
          //   pathName: 'subjects-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'Desktop версия',
            path: '/directories/desktop-version',
            pathName: 'desktop-version-directories',
            topNavIcon: 'clipboard',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'nomenclature',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Номенклатура',
        icon: 'tag',
        path: '/nomenclature',
        pathName: 'nomenclature',
        active: true
      }
    ]
  },
  {
    label: 'holding',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Холдинг',
        icon: 'sunrise',
        path: '/holding',
        pathName: 'holding',
        active: true
      }
    ]
  },
  {
    label: 'brand',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Бренды',
        icon: 'watch',
        path: '/brand',
        pathName: 'brand',
        active: true
      }
    ]
  },
  {
    label: 'users',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Пользователи',
        icon: 'users',
        path: '/users',
        pathName: 'users',
        active: true
      }
    ]
  },
  {
    label: 'bonus',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Бонусы',
        icon: 'award',
        path: '/bonus',
        pathName: 'bonus',
        active: true
      }
    ]
  }
];
