import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import BreakpointsProvider from 'providers/BreakpointsProvider';
import AppProvider from 'providers/AppProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import queryClient from 'services/queryClient';
import { store, persistor } from 'store';
import AlertsProvider from 'providers/AlertsProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AlertsProvider>
            <AppProvider>
              <SettingsPanelProvider>
                <ChatWidgetProvider>
                  <BreakpointsProvider>
                    <RouterProvider router={router} />
                  </BreakpointsProvider>
                </ChatWidgetProvider>
              </SettingsPanelProvider>
            </AppProvider>
          </AlertsProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
