import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const replenishmentBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Виды пополнения баланса',
    active: true
  }
];

export type Replenishment = {
  name: string;
  lang: string;
  status: string;
};

export const replenishment: Replenishment[] = [
  {
    name: 'За наличные средства',
    lang: 'RU',
    status: 'Активный'
  },
  {
    name: 'За счет долга',
    lang: 'RU',
    status: 'Активный'
  }
];
