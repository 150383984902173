/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import authService from './authService';

interface Props {
  loginProps?: any;
  refreshProps?: any;
}

const useAuth = ({ loginProps, refreshProps }: Props) => {
  const login = useMutation(authService.loginFn, loginProps);
  const refresh = useMutation(authService.refreshTokenFn, refreshProps);

  return {
    login,
    refresh
  };
};

export default useAuth;
