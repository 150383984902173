import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const brandBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Бренды',
    active: true
  }
];

export type BrandType = {
  name: string;
  status: string;
  guid: string;
};
