/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';

import Button from 'components/base/Button';

interface UpdataNomVariationTableProps {
  setOpenUpdateVariataion: (show: boolean) => void;
  variationFormData?: any;
  selectedUpdateVariationId?: any;
  setVariationFormData?: any;
}

const UpdataNomVariationTable = ({
  setOpenUpdateVariataion,
  variationFormData,
  selectedUpdateVariationId,
  setVariationFormData
}: UpdataNomVariationTableProps) => {
  const { register, reset, getValues } = useForm();

  //Get by id
  const fetchData = useMemo(() => {
    return () => {
      if (selectedUpdateVariationId === '') return console.log('NO id');

      const computed = variationFormData?.map((el: any) => {
        return {
          variation: el?.product,
          barcode: el?.var_barcode,
          vendor_code: el?.vendor_code,
          party_number: el?.party_number,
          code: el?.code
        };
      });

      reset(computed[selectedUpdateVariationId]);
    };
  }, [selectedUpdateVariationId]);

  // console.log('variationFormData====>', variationFormData);

  const onSubmit = () => {
    const data = getValues();

    if (selectedUpdateVariationId !== '') {
      const updatedFormData = [...variationFormData];
      updatedFormData[selectedUpdateVariationId] = {
        // ...updatedFormData[selectedUpdateVariationId],
        var_data: updatedFormData[selectedUpdateVariationId].var_data,
        nomenclature_id: updatedFormData[selectedUpdateVariationId]
          .nomenclature_id
          ? updatedFormData[selectedUpdateVariationId].nomenclature_id
          : undefined,
        product: data?.variation,
        var_barcode: data?.barcode,
        vendor_code: data.vendor_code,
        party_number: data.party_number,
        code: data.code
      };

      setVariationFormData([...updatedFormData]);
      setOpenUpdateVariataion(false);
    }
  };

  useEffect(() => {
    if (selectedUpdateVariationId !== '') {
      fetchData();
    }
  }, [selectedUpdateVariationId]);

  return (
    <div>
      <Form noValidate>
        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Вариация</Form.Label>
              <Form.Control
                {...register('variation')}
                type="text"
                placeholder="Вариация"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Штрих-код</Form.Label>
              <Form.Control
                {...register('barcode')}
                type="text"
                placeholder="Штрих-код"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Артикул</Form.Label>
              <Form.Control
                {...register('vendor_code')}
                type="text"
                placeholder="Артикул"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Партийный номер</Form.Label>
              <Form.Control
                {...register('party_number')}
                type="text"
                placeholder="Партийный номер"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Код ИКПУ</Form.Label>
              <Form.Control
                {...register('code')}
                type="text"
                placeholder="Код ИКПУ"
              />
            </Form.Group>
          </Col>
        </Row>

        <Button className="w-100" variant="primary" onClick={onSubmit}>
          Сохранить
        </Button>
      </Form>
    </div>
  );
};

export default UpdataNomVariationTable;
