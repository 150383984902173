/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import applicationBonusService from 'services/applicaationBonus';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import CreateApplicationBonus from './CreateApplicationBonus';

export const applicaitonBonusBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Бонусная заявка',
    active: true
  }
];

export type applicationBonusType = {
  name: string;
  status: string;
  guid: string;
};

const BonusApplication = () => {
  const [openBonus, setOpenBonus] = useState(false);
  const [selectedBonusId, setSelectedBonusId] = useState('');

  //Get Bonus
  const { data, refetch } = useQuery(['GET_BONUS_APPLICATION'], () => {
    const res = applicationBonusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.bonus_applications);
    return res;
  });

  const bonusData: applicationBonusType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.ru,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: string) => {
    applicationBonusService.deleteApplicationBonus(id).finally(() => {
      refetch();
      setSelectedBonusId('');
    });
  };

  const bonusTableColumns: ColumnDef<applicationBonusType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="p-1"
            variant="link"
            onClick={() => {
              setOpenBonus(true);
              setSelectedBonusId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            className="p-1"
            variant="link"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Наименование Бонусная заявка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: bonusData,
    columns: bonusTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={applicaitonBonusBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">Бонусная заявка</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder="Поиск Бонусная заявка"
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenBonus(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Новый
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Offcanvas
            show={openBonus}
            onHide={() => {
              setOpenBonus(false);
              setSelectedBonusId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                Новый
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateApplicationBonus
                setOpenBonus={setOpenBonus}
                selectedBonusId={selectedBonusId}
                refetch={refetch}
                setSelectedBonusId={setSelectedBonusId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default BonusApplication;
