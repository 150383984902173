import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const nomenclatureBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Номенклатура',
    active: true
  }
];

export type NomenclatureType = {
  barcode: string;
  category_id: string;
  country_id: string;
  // custom_brand_id: string;
  description: string;
  ikpu: string;
  is_18plus: string;
  legal_entity_id: string;
  measure_unit_id: string;
  measure_unit_kind_id: string;
  name: string;
  package_quantity: string;
  part_number: string;
  product_kind_id: string;
  product_status_id: string;
  short_name: string;
  synonyms: string;
  guid: string;
  variation_id: string;
};
