/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const expensesSourceService = {
  getList: (params: any) =>
    request.get('/guidebook/expense-source', { params }),
  getExpenseById: (id: any) => request.get(`/guidebook/expense-source/${id}`),
  createExpense: (data: any) => request.post('/guidebook/expense-source', data),
  deleteExpense: (id: any) => request.delete(`/guidebook/expense-source/${id}`),
  updateExpense: (id: any, data: any) =>
    request.put(`/guidebook/expense-source/${id}`, data)
};

export default expensesSourceService;
