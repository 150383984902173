import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const activationBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Активация бонуса',
    active: true
  }
];

export type Activation = {
  name: string;
  lang: string;
  status: string;
};

export const activation: Activation[] = [
  {
    name: 'После активации товара',
    lang: 'RU',
    status: 'Активный'
  },
  {
    name: 'После покупки товара',
    lang: 'RU',
    status: 'Активный'
  }
];
