/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const inventoryStatusService = {
  getList: (params: any) =>
    request.get('/guidebook/inventory-status', { params }),
  getInventoryById: (id: any) =>
    request.get(`/guidebook/inventory-status/${id}`),
  createInventory: (data: any) =>
    request.post('/guidebook/inventory-status', data),
  deleteInventory: (id: any) =>
    request.delete(`/guidebook/inventory-status/${id}`),
  updateInventory: (id: any, data: any) =>
    request.put(`/guidebook/inventory-status/${id}`, data)
};

export default inventoryStatusService;
