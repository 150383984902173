/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import './styles.scss';

interface TransformedCategory {
  activity: string;
  name: string;
  text: string;
  status: string;
  guid: string;
  children: TransformedCategory[];
}

interface CategoryAdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
  data: TransformedCategory[];
  columns: any;
}

const CategoryAdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter,
  data,
  columns
}: CategoryAdvanceTableProps) => {
  const { getFooterGroups } = useAdvanceTableContext();

  const [expandedRows, setExpandedRows] = React.useState<
    Record<string, boolean>
  >({});

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows(prev => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  const renderRows = (rows: TransformedCategory[], level: number = 0) => {
    return rows.map((row, index) => {
      const rowId = `${row.guid}-${index}`;
      const isExpanded = expandedRows[rowId];

      return (
        <React.Fragment key={rowId}>
          <tr
            className={classNames(rowClassName, { [`level-${level}`]: true })}
          >
            {columns.map((column: any, colIndex: number) => (
              <td
                key={column.accessorKey || colIndex}
                {...column.meta?.cellProps}
                style={{
                  display: colIndex === 0 ? `flex` : undefined,
                  paddingLeft: colIndex === 0 ? `${level * 20}px` : undefined
                }}
              >
                {colIndex === 0 && row.children && row.children.length > 0 ? (
                  <span
                    onClick={() => toggleRowExpansion(rowId)}
                    className="icons"
                    style={{ marginRight: '10px', cursor: 'pointer' }}
                  >
                    {isExpanded ? (
                      <FeatherIcon icon="chevron-up" size={16} />
                    ) : (
                      <FeatherIcon icon="chevron-down" size={16} />
                    )}
                  </span>
                ) : null}
                {column.cell
                  ? flexRender(column.cell, {
                      row,
                      value:
                        row[column.accessorKey as keyof TransformedCategory]
                    })
                  : row[column.accessorKey as keyof TransformedCategory]}
              </td>
            ))}
          </tr>
          {isExpanded && row.children && renderRows(row.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {columns.map((header: any) => (
              <th
                key={header.accessorKey}
                {...header.meta?.headerProps}
                className={header.meta?.headerProps?.className}
              >
                {flexRender(header.header, header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>{renderRows(data)}</tbody>
        {hasFooter && (
          <tfoot>
            {getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id} className="border-0">
                {footerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.footerProps}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header)}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default CategoryAdvanceTable;
