/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import Button from 'components/base/Button';
import countryService from 'services/country';
import activityService from 'services/activity';
import currencyService from 'services/currency';
import areaService from 'services/area';
import regionService from 'services/region';
import legalEntityStatusService from 'services/legalEntityStatus';
import accessesService from 'services/accesses';
import timezoneService from 'services/timezone';
import legalEntityService from 'services/legalEntity';
import { showAlert } from 'store/alert/alert.thunk';
import ImageUpload from 'components/forms/ImageUpload';

interface CreateHoldingProps {
  setOpenHolding: (show: boolean) => void;
  selectedHoldingId?: any;
  refetch?: any;
}

const CreateHolding = ({
  setOpenHolding,
  selectedHoldingId,
  refetch
}: CreateHoldingProps) => {
  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  const [loading, setLoading] = useState(false);
  const [countryOption, setCountryOption] = useState([]);
  const [activityOption, setActivityOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [legalStatusOption, setLegalStatusOption] = useState([]);
  const [legalAccessOption, setLegalAccessOption] = useState([]);
  const [timezoneOption, setTimezoneOption] = useState([]);

  //GET Activity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((activity: any) => ({
          value: activity.id,
          label: activity.name?.ru
        }));
        setActivityOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.ru
      }));
      setCountryOption(options);
    });
  });

  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await currencyService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.currencies?.map((currency: any) => ({
          value: currency.id,
          label: currency.name?.ru
        }));
        setCurrencyOption(options);
      });
  });

  //GET Area to options
  useQuery(['GET_AREA'], async () => {
    await areaService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.districts?.map((currency: any) => ({
        value: currency.id,
        label: currency.name?.ru
      }));
      setAreaOption(options);
    });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.regions?.map((region: any) => ({
        value: region.id,
        label: region.name?.ru
      }));
      setRegionOption(options);
    });
  });

  //GET Status to options
  useQuery(['GET_LEGAL_ENTITY_STATUS'], async () => {
    await legalEntityStatusService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_statuses?.map((status: any) => ({
          value: status.id,
          label: status.name?.ru
        }));
        setLegalStatusOption(options);
      });
  });

  //GET Access to options
  useQuery(['GET_ACCESS'], async () => {
    await accessesService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entity_accesses?.map((access: any) => ({
          value: access.id,
          label: access.name?.ru
        }));
        setLegalAccessOption(options);
      });
  });

  //GET Timezone to options
  useQuery(['GET_TIMEZONE'], async () => {
    await timezoneService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.time_zones?.map((time: any) => ({
          value: time.id,
          label: time.name?.ru
        }));
        setTimezoneOption(options);
      });
  });

  //Get by id

  const fetchData = useMemo(() => {
    return () => {
      if (selectedHoldingId === '') return setLoading(false);

      legalEntityService
        .getLegalEntityById(selectedHoldingId)
        .then((res: any) => {
          const computed = {
            name: res?.name,
            address: res?.address,
            phone_number: res?.phone_number,
            activity: {
              value: res?.activity_kind_id,
              label: res?.activity_kind?.name?.ru
            },
            country: {
              value: res?.country_id,
              label: res?.country?.name?.ru
            },
            currency: {
              value: res?.currency_id,
              label: res?.currency?.name?.ru
            },
            area: {
              value: res?.district_id,
              label: res?.district?.name?.ru
            },
            region: {
              value: res?.region_id,
              label: res?.region?.name?.ru
            },
            access: {
              value: res?.legal_entity_access_id,
              label: res?.legal_entity_access?.name?.ru
            },
            entity_status: {
              value: res?.legal_entity_status_id,
              label: res?.legal_entity_status?.name?.ru
            },
            logo_url: process.env.REACT_APP_CDN_URL + '/' + res.logo_url
          };
          reset(computed);
        })
        .finally(() => setLoading(false));
    };
  }, [selectedHoldingId]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedHoldingId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.name,
      activity_kind_id: data?.activity?.value,
      address: data?.address,
      country_id: data?.country?.value,
      currency_id: data?.currency?.value,
      district_id: data?.area?.value,
      legal_entity_access_id: data?.access?.value,
      legal_entity_status_id: data?.entity_status?.value,
      legal_entity_type: 'HOLDING',
      phone_number: data?.phone_number,
      region_id: data?.region.value,
      time_zone_id: data?.timezone?.value,
      logo_url: data?.logo_url ? data.logo_url.split('/').pop() : ''
    };

    legalEntityService
      .createLegalEntity(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Типы пользователей успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setOpenHolding(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      activity_kind_id: data?.activity?.value,
      address: data?.address,
      country_id: data?.country?.value,
      currency_id: data?.currency?.value,
      district_id: data?.area?.value,
      legal_entity_access_id: data?.access?.value,
      legal_entity_status_id: data?.entity_status?.value,
      legal_entity_type: 'HOLDING',
      phone_number: data?.phone_number,
      region_id: data?.region.value,
      logo_url: data?.logo_url ? data.logo_url.split('/').pop() : '',
      id: selectedHoldingId
    };
    legalEntityService
      .updateLegalEntity(selectedHoldingId, updateData)
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenHolding(false);
      });
  };

  useEffect(() => {
    if (selectedHoldingId !== '') {
      fetchData();
    }
  }, [selectedHoldingId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Наименование холдинга</Form.Label>
              <Form.Control
                {...register(`name`)}
                type="text"
                placeholder={`Наименование холдинга`}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Адрес</Form.Label>
              <Form.Control
                {...register(`address`)}
                type="text"
                placeholder={`Адрес`}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Телефон номер</Form.Label>
              <Form.Control
                {...register(`phone_number`)}
                type="tel"
                placeholder={`Телефон номер`}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Вид деятельности</Form.Label>
            <div className="react-select-container">
              <Controller
                name="activity"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select activity"
                    options={activityOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Страна</Form.Label>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select country"
                    options={countryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Валюта</Form.Label>
            <div className="react-select-container">
              <Controller
                name="currency"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select currency"
                    options={currencyOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Регион</Form.Label>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select region"
                    options={regionOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Район</Form.Label>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select area"
                    options={areaOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Доступы юридических лиц</Form.Label>
            <div className="react-select-container">
              <Controller
                name="access"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select access"
                    options={legalAccessOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>Состояние юридических лиц</Form.Label>
            <div className="react-select-container">
              <Controller
                name="entity_status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select entity status"
                    options={legalStatusOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        {selectedHoldingId === '' && (
          <Row className="mb-3">
            <Col>
              <Form.Label>Временная зона</Form.Label>
              <div className="react-select-container">
                <Controller
                  name="timezone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      classNamePrefix="react-select"
                      placeholder="Select timezone"
                      options={timezoneOption}
                      styles={{
                        control: base => ({
                          ...base,
                          fontSize: '12.8px'
                        }),
                        singleValue: base => ({
                          ...base,
                          fontSize: '12.8px'
                        }),
                        menuPortal: base => ({
                          ...base,
                          fontSize: '12.8px'
                        })
                      }}
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
        )}

        <Row className="mb-3">
          <Col>
            <Form.Label>Фото</Form.Label>
            <Controller
              name="logo_url"
              control={control}
              defaultValue=""
              render={({ field }) => <ImageUpload {...field} />}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          Добавить
        </Button>
      </Form>
    </div>
  );
};

export default CreateHolding;
