/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import {
  Accept,
  DropEvent,
  FileRejection,
  DropzoneProps as ReactDropZoneProps,
  useDropzone
} from 'react-dropzone';
import Button from './Button';
import imageIcon from 'assets/img/icons/image-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useMemo, useState } from 'react';
import AttachmentPreview, {
  FileAttachment
} from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import uploadService from 'services/fileService';

interface DropzoneProps {
  className?: string;
  size?: 'sm';
  reactDropZoneProps?: ReactDropZoneProps;
  accept?: Accept;
  noPreview?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onChange?: (value: string | null) => void;
}

const NewDropzone = ({
  className,
  size,
  onDrop,
  accept,
  noPreview,
  reactDropZoneProps,
  children,
  onChange
}: PropsWithChildren<DropzoneProps>) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<FileAttachment[]>([]);

  const handleRemoveFile = (index: number) => {
    setFiles(files.filter((file, ind) => index !== ind));
    setPreviews(previews.filter((file, ind) => index !== ind));
    onChange && onChange(null); // Call onChange with null when file is removed
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setFiles(acceptedFiles);
      setPreviews(acceptedFiles.map(file => convertFileToAttachment(file)));

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];

        const data = new FormData();
        data.append('file', file);

        try {
          await uploadService.uploadImage(data).then((res: any) => {
            const imageUrl = process.env.REACT_APP_CDN_URL + '/' + res.filename;
            onChange && onChange(imageUrl); // Call onChange with the CDN URL
          });
        } catch (error) {
          console.log('error', error);
        }
      }

      if (onDrop) {
        onDrop(acceptedFiles, fileRejections, event);
      }
    },
    accept,
    ...reactDropZoneProps
  });

  const imageOnly = useMemo(() => {
    return Boolean(accept && accept['image/*']);
  }, [accept]);

  return (
    <>
      {imageOnly && !noPreview && previews.length > 0 && (
        <div className="d-flex flex-wrap gap-2 mb-2">
          {previews.map((file: any, index) => (
            <ImageAttachmentPreview
              key={file.name}
              image={URL.createObjectURL(file)}
              handleClose={() => handleRemoveFile(index)}
            />
          ))}
        </div>
      )}

      {previews.length === 0 && (
        <div
          {...getRootProps()}
          className={classNames(className, 'dropzone', {
            'dropzone-sm': size === 'sm'
          })}
        >
          <input {...getInputProps()} />
          {children ? (
            <>{children}</>
          ) : (
            <div className="text-600 fw-bold fs-9">
              Drag your {imageOnly ? 'photo' : 'files'} here{' '}
              <span className="text-800">or </span>
              <Button variant="link" className="p-0">
                Browse from device
              </Button>
              <br />
              <img
                className="mt-3"
                src={imageIcon}
                width={classNames({ 24: size === 'sm', 40: size !== 'sm' })}
                alt=""
              />
            </div>
          )}
        </div>
      )}

      {!imageOnly &&
        previews.map((file, index) => (
          <div
            key={index}
            className={classNames(
              'border-bottom d-flex align-items-center justify-content-between py-3'
            )}
          >
            <AttachmentPreview attachment={file} />

            <button className="btn p-0" onClick={() => handleRemoveFile(index)}>
              <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
            </button>
          </div>
        ))}
    </>
  );
};

export default NewDropzone;
