/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const assessmentMehodService = {
  getList: (params: any) =>
    request.get('/guidebook/assessment-method', { params }),
  getAssessMethodById: (id: any) =>
    request.get(`/guidebook/assessment-method/${id}`),
  createAssessMethod: (data: any) =>
    request.post('/guidebook/assessment-method', data),
  deleteAssessMethod: (id: any) =>
    request.delete(`/guidebook/assessment-method/${id}`),
  updateAssessMethod: (id: any, data: any) =>
    request.put(`/guidebook/assessment-method/${id}`, data)
};

export default assessmentMehodService;
