import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const genderBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Пол',
    active: true
  }
];

export type Gender = {
  name: string;
  lang: string;
  status: string;
};

export const gender: Gender[] = [
  {
    name: 'Мужской',
    lang: 'RU',
    status: 'Активный'
  },
  {
    name: 'Женский',
    lang: 'RU',
    status: 'Активный'
  }
];
