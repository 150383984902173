/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const statusService = {
  getList: (params: any) =>
    request.get('/guidebook/product-status', { params }),
  getStatusById: (id: any) => request.get(`/guidebook/product-status/${id}`),
  createStatus: (data: any) => request.post('/guidebook/product-status', data),
  deleteStatus: (id: any) => request.delete(`/guidebook/product-status/${id}`),
  updateStatus: (id: any, data: any) =>
    request.put(`/guidebook/product-status/${id}`, data)
};

export default statusService;
