/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps, Nav, Form } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useEffect } from 'react';
import { Menu } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { tableSettingsActions } from 'store/tableSettings/tableSettings.slice';
import './styles.scss';
import NODATAICON from 'assets/img/icons/no-data-icon.svg';

interface AdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
}

const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter
}: AdvanceTableProps) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders, getFooterGroups } = table;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const list = useSelector(
    (state: { tableSettings: any }) =>
      state.tableSettings?.tableSlug?.[pathname]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (list) {
      setCheckedItems(list);
    }
  }, [list]);

  const [checkedItems, setCheckedItems] = useState<string[]>(list || []);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {getFlatHeaders().map(header => {
              const isHidden = list?.includes(header?.id);
              return (
                <>
                  {!isHidden && (
                    <th
                      key={header.id}
                      {...header.column.columnDef.meta?.headerProps}
                      className={classNames(
                        header.column.columnDef.meta?.headerProps?.className,
                        {
                          sort: header.column.getCanSort(),
                          desc: header.column.getIsSorted() === 'desc',
                          asc: header.column.getIsSorted() === 'asc'
                        }
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  )}
                </>
              );
            })}
            <th style={{ width: '2%', textAlign: 'end' }}>
              <Nav.Item>
                <Link to="#" onClick={handleClick}>
                  <FeatherIcon icon="settings" size={20} />
                </Link>
                <Menu
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  classes={{ list: 'menu', paper: 'paper' }}
                >
                  <div className="menu-item">
                    {getFlatHeaders().map(header => {
                      const columnDefHeader = header.column.columnDef.header;

                      if (typeof columnDefHeader === 'function') {
                        return null;
                      }

                      const labelContent = flexRender(
                        columnDefHeader,
                        header.getContext()
                      );
                      if (labelContent === undefined) {
                        return null;
                      }
                      return (
                        <Form.Check
                          key={header.id}
                          type="checkbox"
                          label={header.isPlaceholder ? null : labelContent}
                          onChange={e => {
                            const id = header.id;
                            const newCheckedItems = e.target.checked
                              ? [...checkedItems, id]
                              : checkedItems.filter(item => item !== id);
                            setCheckedItems(newCheckedItems);
                            dispatch(
                              tableSettingsActions.addSettings({
                                pathname: pathname,
                                checkedItems: newCheckedItems
                              })
                            );
                          }}
                          checked={list?.includes(header?.id)}
                        />
                      );
                    })}
                  </div>
                </Menu>
              </Nav.Item>
            </th>
          </tr>
        </thead>

        {getRowModel().rows?.length === 0 ? (
          <tbody className="container">
            <tr>
              <td colSpan={getFlatHeaders().length}>
                <div className="box">
                  <img src={NODATAICON} alt="" />
                  <p className="text">Вы еще не добавили информацию!</p>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody className={bodyClassName}>
            {getRowModel().rows.map(row => (
              <tr key={row.id} className={classNames(rowClassName)}>
                {row.getVisibleCells().map(cell => {
                  const isHidden = list?.includes(cell?.column?.id);
                  return (
                    <React.Fragment key={cell.id}>
                      {!isHidden && (
                        <td
                          key={cell.id}
                          {...cell.column.columnDef.meta?.cellProps}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )}
                    </React.Fragment>
                  );
                })}
              </tr>
            ))}
          </tbody>
        )}
        {hasFooter && (
          <tfoot>
            {getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id} className="border-0">
                {footerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...header.column.columnDef.meta?.footerProps}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default AdvanceTable;
