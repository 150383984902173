/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import styles from './styles.module.scss';

interface PhoneNumberInputProps {
  unicode?: any;
  placeholder?: any;
  field?: any;
}

const PhoneNumberInput = ({
  unicode,
  placeholder,
  field
}: PhoneNumberInputProps) => {
  return (
    <PhoneInput
      international
      defaultCountry={unicode}
      placeholder={placeholder}
      className={styles.phoneInput}
      {...field}
    />
  );
};

export default PhoneNumberInput;
