/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Offcanvas } from 'react-bootstrap';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import bonusesService from 'services/bonuses';
import Button from 'components/base/Button';
import CreateBonus from './CreateBonus';

export const bonusesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Бонусы',
    active: true
  }
];

export type BonusType = {
  product_name: string;
  date_start: string;
  date_end: string;
  system_approved: string;
  status: string;
  guid: string;
  is_system_approved: boolean;
};

const Bonus = () => {
  const [openBonus, setOpenBonus] = useState(false);
  const [selectedBonusId, setSelectedBonusId] = useState('');

  //Get BONUS
  const { data, refetch } = useQuery(['GET_BONUS'], () => {
    const res = bonusesService
      .getList({ offset: 0, limit: 10 })
      .then((res: any) => res?.bonuses);
    return res;
  });

  const bonusData: BonusType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_name: items?.name,
          is_system_approved: items?.is_system_approved ?? false,
          date_start: new Date(items?.date_from)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),
          date_end: new Date(items?.date_to)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),
          system_approved: items?.is_system_approved ? 'Да' : 'Нет',
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const bonusDataTableColumns: ColumnDef<BonusType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex gap-2">
            <Button
              variant="link"
              className="p-2"
              onClick={() => {
                setSelectedBonusId(rowData?.row?.original?.guid);
                setOpenBonus(true);
              }}
            >
              <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_name',
      header: 'Наименование бонуса',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_start',
      header: 'Дата начала',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_end',
      header: 'Дата окончания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'system_approved',
      header: 'Одобрено системой',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: bonusData,
    columns: bonusDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <div>
      <PageBreadcrumb items={bonusesBreadcrumbItems} />

      <div>
        <div className="d-flex justify-content-between">
          <h2>Бонусы</h2>
        </div>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
      </div>

      <Offcanvas
        show={openBonus}
        onHide={() => {
          setOpenBonus(false);
          setSelectedBonusId('');
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Обновлять
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateBonus
            setOpenBonus={setOpenBonus}
            selectedBonusId={selectedBonusId}
            refetch={refetch}
            setSelectedBonusId={setSelectedBonusId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Bonus;
