import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const areaBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Район',
    active: true
  }
];

export type AreaType = {
  country: string;
  region: string;
  area: string;
  status: string;
  guid: string;
};
