import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const ndsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'НДС',
    active: true
  }
];

export type NDSType = {
  country: string;
  nds: string;
  status: string;
  guid: string;
};
