/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const paymentTypeService = {
  getList: (params: any) => request.get('/guidebook/payment-type', { params }),
  getPaymentTypeById: (id: any) => request.get(`/guidebook/payment-type/${id}`),
  createPaymentType: (data: any) =>
    request.post('/guidebook/payment-type', data),
  deletePaymentType: (id: any) =>
    request.delete(`/guidebook/payment-type/${id}`),
  updatePaymentType: (id: any, data: any) =>
    request.put(`/guidebook/payment-type/${id}`, data)
};

export default paymentTypeService;
