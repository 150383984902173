/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { Menu } from '@mui/material';

// import FeatherIcon from 'feather-icons-react';
// import NineDotMenu from './NineDotMenu';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ThemeToggler from 'components/common/ThemeToggler';
// import NotificationDropdownMenu from './NotificationDropdownMenu';
// import { useState } from 'react';
// import DropdownSearchBox from 'components/common/DropdownSearchBox';
// import SearchResult from 'components/common/SearchResult';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import ProfileDropdownMenu from './ProfileDropdownMenu';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/icons/avatarImg.png';
import GBFlag from 'assets/img/icons/great-britian-flag.svg';
import UzbFlag from 'assets/img/icons/uzb-flag.svg';
import RussianFlag from 'assets/img/icons/russian-flag.svg';
import styles from './styles.module.scss';

const languages = [
  {
    icon: GBFlag,
    title: 'English',
    slug: 'en'
  },
  {
    icon: RussianFlag,
    title: 'Русский',
    slug: 'ru'
  },
  {
    icon: UzbFlag,
    title: "O'zbek",
    slug: 'uz'
  }
];

const NavItemsSlim = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (lang: any) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  const activeLang = useMemo(() => {
    return languages.find(lang => lang.slug === i18n.language);
  }, [i18n.language]);

  const open = Boolean(anchorEl);

  // const [openSearchModal, setOpenSearchModal] = useState(false);
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {/* <Nav.Item>
        <ThemeToggler slim />
      </Nav.Item>
      <Nav.Item>
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={12} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <FeatherIcon icon="bell" size={12} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Dropdown.Toggle>
          <NineDotMenu />
        </Dropdown>
      </Nav.Item> */}

      <Nav.Item>
        <div className={styles.btn} onClick={handleClick}>
          <img style={{ width: '20px' }} src={activeLang?.icon} alt="" />
        </div>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ list: styles.menu, paper: styles.paper }}
        >
          {languages.map(language => (
            <div
              key={language.slug}
              className={styles.menuItem}
              onClick={() => handleRowClick(language.slug)}
            >
              <img style={{ width: '20px' }} src={language.icon} alt="" />
              <p className={styles.itemText}>{language.title}</p>
            </div>
          ))}
        </Menu>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0"
            variant=""
          >
            <Avatar src={avatar} size="xl" />
            {/* Olivia <FontAwesomeIcon icon={faChevronDown} className="fs-10" /> */}
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      {/* <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default NavItemsSlim;
