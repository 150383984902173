import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

import { authReducer } from './auth/auth.slice';
import { alertReducer } from './alert/alert.slice';
import { tableSettingsReducer } from './tableSettings/tableSettings.slice';

const authPersistConfig = {
  key: 'auth',
  storage
};

const tableSettingsPersistConfig = {
  key: 'tableSettings',
  storage
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  tableSettings: persistReducer(
    tableSettingsPersistConfig,
    tableSettingsReducer
  ),
  alert: alertReducer
});

export default rootReducer;
