import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const applicationBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Область применения',
    active: true
  }
];

export type Application = {
  name: string;
  lang: string;
  status: string;
};

export const application: Application[] = [
  {
    name: 'Мастер',
    lang: 'RU',
    status: 'Активный'
  },
  {
    name: 'Мастер',
    lang: 'RU',
    status: 'Активный'
  }
];
