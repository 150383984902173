import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const currencyBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Валюта',
    active: true
  }
];

export type CurrencyType = {
  currency: string;
  code: string;
  digital_code: string;
  max_cost: string;
  country: string;
  main_currency: string;
  status: string;
  guid: string;
};
