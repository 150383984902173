/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returningService = {
  getList: (params: any) =>
    request.get('/guidebook/product-return-reason', { params }),
  getReturningById: (id: any) =>
    request.get(`/guidebook/product-return-reason/${id}`),
  createReturning: (data: any) =>
    request.post('/guidebook/product-return-reason', data),
  deleteReturning: (id: any) =>
    request.delete(`/guidebook/product-return-reason/${id}`),
  updateReturning: (id: any, data: any) =>
    request.put(`/guidebook/product-return-reason/${id}`, data)
};

export default returningService;
