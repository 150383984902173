/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const versioinService = {
  getList: (params: any) => request.get('/version', { params }),
  getVersionById: (id: any) => request.get(`/version/${id}`),
  createVersion: (data: any) => request.post('/version', data),
  deleteVersion: (id: any) => request.delete(`/version/${id}`),
  updateVersion: (id: any, data: any) => request.put(`/version/${id}`, data)
};

export default versioinService;
