/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import LegalAccessesTable from './LegalAccessesTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { AccessesType, accessesBreadcrumbItems } from 'data/accesses';
import Button from 'components/base/Button';
import CreateLegalAccesses from './CreateLegalAccesses';
import accessesService from 'services/accesses';

const LegalAccesses = () => {
  const [openAccess, setOpenAccess] = useState(false);
  const [selectedAccessId, setSelectedAccessId] = useState('');

  //Get Access
  const { data, refetch } = useQuery(['GET_ACCESS'], () => {
    const res = accessesService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entity_accesses);
    return res;
  });

  const accessData: AccessesType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.ru,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: any) => {
    accessesService.deleteAccesses(id).finally(() => {
      refetch();
      setSelectedAccessId('');
    });
  };

  const accessesTableColumns: ColumnDef<AccessesType>[] = [
    {
      accessorKey: 'name',
      header: 'Наименование типа доступа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'is_default',
      header: 'Доступы юридических лиц по умолчанию',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenAccess(true);
                setSelectedAccessId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: accessData,
    columns: accessesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={accessesBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Доступы юридических лиц</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search legal accesses"
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenAccess(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Добавить
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <LegalAccessesTable />
          </div>

          <Modal
            show={openAccess}
            onHide={() => {
              setOpenAccess(false);
              setSelectedAccessId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Новый
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateLegalAccesses
                setOpenAccess={setOpenAccess}
                selectedAccessId={selectedAccessId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default LegalAccesses;
