/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const paymentMethodService = {
  getList: (params: any) =>
    request.get('/guidebook/payment-method', { params }),
  getPaymentMethodById: (id: any) =>
    request.get(`/guidebook/payment-method/${id}`),
  createPaymentMethod: (data: any) =>
    request.post('/guidebook/payment-method', data),
  deletePaymentMethod: (id: any) =>
    request.delete(`/guidebook/payment-method/${id}`),
  updatePaymentMethod: (id: any, data: any) =>
    request.put(`/guidebook/payment-method/${id}`, data)
};

export default paymentMethodService;
