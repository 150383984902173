import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
  token: {},
  user: {}
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
      state.isAuth = true;
      state.user = payload.user;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    logout: () => initialState
  }
});
