/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const articleService = {
  getList: (params: any) => request.get('/guidebook/article', { params }),
  getArticleById: (id: any) => request.get(`/guidebook/article/${id}`),
  createArticle: (data: any) => request.post('/guidebook/article', data),
  deleteArticle: (id: any) => request.delete(`/guidebook/article/${id}`),
  updateArticle: (id: any, data: any) =>
    request.put(`/guidebook/article/${id}`, data)
};

export default articleService;
