/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const paymentPurposeService = {
  getList: (params: any) =>
    request.get('/guidebook/payment-purpose', { params }),
  getPaymentPurposeById: (id: any) =>
    request.get(`/guidebook/payment-purpose/${id}`),
  createPaymentPurpose: (data: any) =>
    request.post('/guidebook/payment-purpose', data),
  deletePaymentPurpose: (id: any) =>
    request.delete(`/guidebook/payment-purpose/${id}`),
  updatePaymentPurpose: (id: any, data: any) =>
    request.put(`/guidebook/payment-purpose/${id}`, data)
};

export default paymentPurposeService;
