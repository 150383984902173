/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import { brandBreadcrumbItems, BrandType } from 'data/brand';
import brandService from 'services/brand';
import CreateBrand from './CreateBrand';
import BrandTable from './BrandTable';

const Brand = () => {
  const [openBrand, setOpenBrand] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState('');

  //Get Brand
  const { data, refetch } = useQuery(['GET_BRAND'], () => {
    const res = brandService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.custom_brands);
    return res;
  });

  const brandData: BrandType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.ru,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: any) => {
    brandService.deleteBrand(id).finally(() => {
      refetch();
      setSelectedBrandId('');
    });
  };

  const brandTableColumns: ColumnDef<BrandType>[] = [
    {
      accessorKey: 'name',
      header: 'Наименование бренда',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenBrand(true);
                setSelectedBrandId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: brandData,
    columns: brandTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={brandBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Бренды</h2>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mb-4">
          <Row className="d-flex justify-content-between">
            <Col xs="auto">
              <SearchBox
                placeholder="Search brand"
                onChange={handleSearchInputChange}
              />
            </Col>

            <Col xs="auto">
              <Button
                className="mt-3"
                variant="primary"
                onClick={() => setOpenBrand(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Добавить
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <BrandTable />
        </div>

        <Modal
          show={openBrand}
          onHide={() => {
            setOpenBrand(false);
            setSelectedBrandId('');
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Новый</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateBrand
              setOpenBrand={setOpenBrand}
              selectedBrandId={selectedBrandId}
              refetch={refetch}
            />
          </Modal.Body>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default Brand;
