/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const rolesService = {
  getList: (params: any) => requestAuth.get('/role', { params }),
  getRoleById: (id: any) => requestAuth.get(`/role/${id}`),
  createRole: (data: any) => requestAuth.post('/role', data),
  deleteRole: (id: any) => requestAuth.delete(`/role/${id}`),
  updateRole: (id: any, data: any) => requestAuth.put(`/role/${id}`, data)
};

export default rolesService;
