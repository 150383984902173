import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';

import Button from 'components/base/Button';
import DocPageHeader from 'components/docs/DocPageHeader';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const breadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Активация бонуса',
    url: '/directories/bonuses/activation'
  },
  {
    label: 'Create',
    active: true
  }
];

const options = [
  {
    value: 'active',
    label: 'Активный'
  },
  {
    value: 'blocked',
    label: 'Заблокирован'
  }
];

const langOptions = [
  {
    value: 'ru',
    label: 'Руский'
  },
  {
    value: 'uz',
    label: "O'zbek"
  }
];

const CreateActivation = () => {
  const { register, handleSubmit, control } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = data => {
    console.log('data', data);
  };
  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />

      <DocPageHeader title="Add activation" />

      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="p-3 bg-white rounded"
      >
        <Row className="mb-3 gx-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Наименование активации бонуса</Form.Label>
              <Form.Control
                {...register('name')}
                type="text"
                placeholder="activation"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Label>Состояние</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select status"
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
          <Col>
            <Form.Label>Язык</Form.Label>
            <div className="react-select-container">
              <Controller
                name="lang"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select lang"
                    options={langOptions}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Submit form
        </Button>
      </Form>
    </div>
  );
};

export default CreateActivation;
