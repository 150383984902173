/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import CreateNomenclature from './CreateNomenclature';
import TableNomenclature from './TableNomenclature';

import {
  nomenclatureBreadcrumbItems,
  NomenclatureType
} from 'data/nomenclature';
import nomenclatureService from 'services/nomenclature';

const Nomenclature = () => {
  const [openNomenclature, setOpenNomenclature] = useState(false);
  const [selectedNomenclatureId, setSelectedNomenclatureId] = useState('');

  //Get Nomenclature
  const { data, refetch } = useQuery(['GET_NOMENCLATURE'], () => {
    const res = nomenclatureService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.nomenclatures);
    return res;
  });

  const nomenclatureData: NomenclatureType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          ...items,
          category_id: items?.category?.name?.ru,
          product_kind_id: items?.productKind?.name?.ru,
          country_id: items?.country?.name?.ru,
          legal_entity_id: items?.legal_entity?.name,
          measure_unit_id: items?.measure_unit?.name?.ru,
          measure_unit_kind_id: items?.measure_unit_kind?.name?.ru,
          product_status_id: items?.product_status?.name?.ru,
          custom_brand_id: items?.custom_brand?.name?.ru,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: any) => {
    nomenclatureService.deleteNomenclature(id).finally(() => {
      refetch();
      setSelectedNomenclatureId('');
    });
  };

  const nomenclatureTableColumns: ColumnDef<NomenclatureType>[] = [
    {
      accessorKey: 'name',
      header: 'Полный наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'short_name',
      header: 'Короткий наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'custom_brand_id',
      header: 'Бренд',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'category_id',
      header: 'Категория',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country_id',
      header: 'Страна',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'legal_entity_id',
      header: 'Холдинг',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit_id',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'measure_unit_kind_id',
      header: 'Тип единицы измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'package_quantity',
      header: 'Количество упаковки',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'part_number',
      header: 'Партийный номер',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'product_kind_id',
      header: 'Тип товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'product_status_id',
      header: 'Состояние товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'ikpu',
      header: 'Код ИКПУ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'synonyms',
      header: 'Синонимы',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'description',
      header: 'Описания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenNomenclature(true);
                setSelectedNomenclatureId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: nomenclatureData,
    columns: nomenclatureTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={nomenclatureBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">Номенклатура</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search holding"
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenNomenclature(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Добавить
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <TableNomenclature />
          </div>

          <Modal
            show={openNomenclature}
            onHide={() => {
              setOpenNomenclature(false);
              setSelectedNomenclatureId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Новый
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateNomenclature
                setOpenNomenclature={setOpenNomenclature}
                selectedNomenclatureId={selectedNomenclatureId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Nomenclature;
