/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { useState } from 'react';

import variationService from 'services/variation';
import Button from 'components/base/Button';

interface CreateNomVariationProps {
  setFormData: any;
  formData: any;
}

const CreateNomVariation = ({
  setFormData,
  formData
}: CreateNomVariationProps) => {
  const { register, control, setValue, getValues } = useForm();
  const [variationOption, setVariationOption] = useState([]);

  //GET Variation to options
  useQuery(['GET_VARIATION'], async () => {
    await variationService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.variations?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setVariationOption(options);
      });
  });

  const onSubmit = () => {
    const data = getValues();
    const formDataItem = {
      var_value: data.name,
      variation: data.variation?.label,
      variation_id: data?.variation?.value
    };
    setFormData([...formData, formDataItem]);
  };

  return (
    <div>
      <Form noValidate>
        <Row className="mb-3 gx-3">
          <Col>
            <Form.Label>Вариация</Form.Label>
            <div className="react-select-container">
              <Controller
                name="variation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select variation"
                    options={variationOption}
                    onChange={selectedOption => {
                      field.onChange(selectedOption);
                      setValue('name', ''); // Clear the name input field
                    }}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>

          <Col className="d-flex gap-3">
            <Form.Group className="md-4 flex-grow-1 bd-highlight">
              <Form.Label>Значения</Form.Label>
              <Form.Control
                {...register('name')}
                type="text"
                placeholder="Значения"
              />
            </Form.Group>
            <Button
              className="mt-4 bd-highlight"
              variant="primary"
              type="button"
              onClick={onSubmit}
            >
              Новый
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateNomVariation;

// const productVariations = [
//   {
//     name: 'Цвет',
//     values: [
//       {
//         var_value: 'red'
//       },
//       {
//         var_value: 'green'
//       },
//       {
//         var_value: 'black'
//       }
//     ]
//   },
//   {
//     name: 'Размер',
//     values: [{ value: 'S' }, { value: 'L' }, { value: 'XL' }]
//   },
//   {
//     name: 'Диагональ',
//     values: [{ value: '12' }, { value: '14' }, { value: '16' }]
//   }
// ];
