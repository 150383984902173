/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const measurementService = {
  getList: (params: any) =>
    request.get('/guidebook/measure-unit-kind', { params }),
  getMeasurementById: (id: any) =>
    request.get(`/guidebook/measure-unit-kind/${id}`),
  createMeasurement: (data: any) =>
    request.post('/guidebook/measure-unit-kind', data),
  deleteMeasurement: (id: any) =>
    request.delete(`/guidebook/measure-unit-kind/${id}`),
  updateMeasurement: (id: any, data: any) =>
    request.put(`/guidebook/measure-unit-kind/${id}`, data)
};

export default measurementService;
