/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const rateDegreeService = {
  getList: (params: any) => request.get('/guidebook/rate-degree', { params }),
  getRateDegreeById: (id: any) => request.get(`/guidebook/rate-degree/${id}`),
  createRateDegree: (data: any) => request.post('/guidebook/rate-degree', data),
  deleteRateDegree: (id: any) => request.delete(`/guidebook/rate-degree/${id}`),
  updateRateDegree: (id: any, data: any) =>
    request.put(`/guidebook/rate-degree/${id}`, data)
};

export default rateDegreeService;
