/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const usersService = {
  getList: (params: any) => requestAuth.get('/user', { params }),
  getById: (id: string) => requestAuth.get(`/user/${id}`),
  create: (data: any) => requestAuth.post('/user', data),
  update: (data: any) => requestAuth.put(`/user`, data)
};

export default usersService;
