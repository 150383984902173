/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import paymentOrderService from 'services/paymentOrder';

const options = [
  {
    value: true,
    label: 'Активный'
  },
  {
    value: false,
    label: 'Заблокирован'
  }
];

interface CreatePaymentProcedureProps {
  setOpenProcedure: (show: boolean) => void;
  selectedProcedureId?: any;
  refetch?: any;
}

const CreatePaymentProcedure = ({
  setOpenProcedure,
  selectedProcedureId,
  refetch
}: CreatePaymentProcedureProps) => {
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //Get by id
  const fetchData = () => {
    if (selectedProcedureId === '') return setLoading(false);

    paymentOrderService
      .getPaymentOrderById(selectedProcedureId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          status: options.find(option => option.value === res?.status)
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedProcedureId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      status: data?.status.value
    };

    paymentOrderService
      .createPaymentOrder(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Типы единиц измерения успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenProcedure(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      status: data?.status.value,
      id: selectedProcedureId
    };
    paymentOrderService
      .updatePaymentOrder(selectedProcedureId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenProcedure(false);
      });
  };

  useEffect(() => {
    if (selectedProcedureId !== '') {
      fetchData();
    }
  }, [langData, selectedProcedureId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        {langData &&
          langData.map((item: any) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Form.Group className="md-4">
                  <Form.Label>
                    Наименование порядок оплат ( {item.char_code} )
                  </Form.Label>
                  <Form.Control
                    {...register(`name_${item.char_code.toLowerCase()}`)}
                    type="text"
                    placeholder={`Наименование порядок оплат ${item.char_code}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Form.Label>Состояние</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select status"
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          Добавить
        </Button>
      </Form>
    </div>
  );
};

export default CreatePaymentProcedure;
