import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { PaymentMethod } from 'data/paymentmethod';

export const paymentMethodTableColumns: ColumnDef<PaymentMethod>[] = [
  {
    accessorKey: 'name',
    header: 'Наименование способ оплаты',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояние',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'lang',
    header: 'Язык',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    id: 'action',
    cell: rowData => (
      <div>
        <Link
          to={`/directories/bonuses/payment/create-payment/${rowData?.row?.id}`}
        >
          <FeatherIcon
            icon="edit-2"
            className="cursor-pointer"
            size={16}
            onClick={() => console.log('rowData', rowData)}
          />
        </Link>
      </div>
    ),
    meta: {
      headerProps: { style: { width: '7%' } },
      cellProps: { className: 'text-end' }
    }
  }
];

const PaymentMethodTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default PaymentMethodTable;
