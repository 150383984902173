/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';

import './styles.scss';

const Alerts = () => {
  const alerts = useSelector(
    (state: { alert: { alerts: any } }) => state.alert.alerts
  );
  return (
    <div className="Alerts">
      {alerts.map((alert: any) => {
        return (
          <Alert
            key={alert.id}
            variant={alert.type === 'error' ? 'danger' : alert.type}
            className="alert shake-animation"
          >
            {alert.title}
          </Alert>
        );
      })}
    </div>
  );
};

export default Alerts;
