/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import countryService from 'services/country';
import CountryTable from './CountryTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { countryBreadcrumbItems } from 'data/country';
import Button from 'components/base/Button';
import CreateCountry from './CreateCountry';
import { CountryType } from 'data/country';

const Country = () => {
  const [openCountry, setOpenCountry] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState('');

  //Get Country
  const { data, refetch } = useQuery(['GET_COUNTRY'], () => {
    const res = countryService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.countries);
    return res;
  });

  const countryData: CountryType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          full_name: items?.name?.ru,
          short_name: items?.short_name,
          code_lang: items?.char_code,
          code: items?.char_code_2,
          num_code: items?.num_code,
          phone_code: items?.phone_code,
          phone_mask: items?.phone_mask,
          language: items?.language?.name,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: any) => {
    countryService.deleteCountry(id).finally(() => {
      refetch();
      setSelectedCountryId('');
    });
  };

  const countryTableColumns: ColumnDef<CountryType>[] = [
    {
      accessorKey: 'full_name',
      header: 'Полное название',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'short_name',
      header: 'Краткое название',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'language',
      header: 'Язык',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'code_lang',
      header: 'Код языка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'code',
      header: 'Буквенный код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'num_code',
      header: 'Цифровой код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'phone_code',
      header: 'Телефонный код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone_mask',
      header: 'Телефонный формат',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenCountry(true);
                setSelectedCountryId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: countryData,
    columns: countryTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={countryBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Страна</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search country"
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  variant="primary"
                  className="mt-3"
                  onClick={() => setOpenCountry(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Добавить
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <CountryTable />
          </div>

          <Modal
            show={openCountry}
            onHide={() => {
              setOpenCountry(false);
              setSelectedCountryId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Новый
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateCountry
                setOpenCountry={setOpenCountry}
                selectedCountryId={selectedCountryId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Country;
