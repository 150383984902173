/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const bonusesService = {
  getList: (params: any) => request.get('/system-bonus', { params }),
  getBonusById: (id: any) => request.get(`/bonus/${id}`),
  updateBonus: (id: any, data: any) => request.put(`/system-bonus/${id}`, data)
};

export default bonusesService;
