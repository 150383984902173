import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import ApplicationTable from './ApplicationTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { applicationTableColumns } from './ApplicationTable';
import { application, applicationBreadcrumbItems } from 'data/application';

const Application = () => {
  const table = useAdvanceTable({
    data: application,
    columns: applicationTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={applicationBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Область применения</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search application"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Link
                  className="btn btn-primary px-5"
                  to="/directories/bonuses/application/create-application"
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add application
                </Link>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <ApplicationTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Application;
