/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faKey } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/base/Button';
import { authActions } from 'store/auth/auth.slice';
import useAuth from 'services/auth/useAuth';

const SignInForm = () => {
  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required('Обязательное поле')
      .matches(
        /^(\+998)(9[0-9])([0-9]{3})([0-9]{2})([0-9]{2})$/,
        'Введите действительный номер телефона'
      ),

    password: yup.string().required('Обязательное поле')
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login } = useAuth({
    loginProps: {
      onSuccess: (value: any) => {
        const states = {
          token: value.data.access_token,
          user: value.data.user
        };
        dispatch(authActions.login(states));
        navigate('/');
      }
    }
  });

  const onSubmit = (data: any) => {
    console.log('dd', data);
    login.mutate({
      phone_number: data.phone_number,
      password: data.password
    });
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center mb-7">
        <h3 className="text-1000">Войти</h3>
        <p className="text-700">Получите доступ к своей учетной записи</p>
      </div>

      <Form.Group className="mb-3">
        <Form.Label>Номер телефона</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            {...register('phone_number')}
            type="phone"
            className="form-icon-input"
            placeholder="+998999999999"
            isInvalid={!!errors.phone_number}
          />
          {errors.phone_number && (
            <Form.Control.Feedback type="invalid">
              {errors.phone_number.message}
            </Form.Control.Feedback>
          )}
          <FontAwesomeIcon icon={faPhone} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="password">Пароль</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            {...register('password')}
            type="password"
            className="form-icon-input"
            placeholder="Password"
            isInvalid={!!errors.password}
          />
          {errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>

      <Button type="submit" variant="primary" className="w-100 mb-3">
        Войти
      </Button>
    </Form>
  );
};

export default SignInForm;
